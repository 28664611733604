import React from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './styled';
import { CloseIcon } from '../../Icons';

const AvailableUsers = ({ onClose, handlerChooseUser, users }) => {
  const { t } = useTranslation();

  return (
    <S.Wrapper>
      <S.Header>
        <S.HeaderText>{t('modal.choose_user')}</S.HeaderText>
        <div onClick={onClose}>
          <CloseIcon />
        </div>
      </S.Header>

      <S.ExplainText>{t('modal.choose_user_explain')}</S.ExplainText>

      <S.Content>
        {users?.length ? (
          users?.map((user) => (
            <S.UserItem key={user.evcoId} onClick={() => handlerChooseUser(user)}>
              {user.name}
            </S.UserItem>
          ))
        ) : (
          <p>No assigned users...</p>
        )}
      </S.Content>
    </S.Wrapper>
  );
};

export default AvailableUsers;
