import React from 'react';

import * as S from './styled';

const Checkbox = ({ value, onChecked, label, checked }) => {
  const handlerChecked = (e) => {
    onChecked(e.target.value);
  };

  return (
    <S.FormControlWrapper
      label={label}
      control={<S.CustomCheckbox value={value} checked={checked} onChange={handlerChecked} />}
    />
  );
};

export default Checkbox;
