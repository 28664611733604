import React from 'react';

import * as S from './styled';
import Navigation from '../../Sidebar/Navigation';
import { CloseIcon } from '../../../Icons';

const BurgerMenuContent = ({ onClose }) => {
  return (
    <S.MenuWrapper>
      <S.MenuClose onClick={onClose}>
        <CloseIcon />
      </S.MenuClose>
      <Navigation onClose={onClose} />
    </S.MenuWrapper>
  );
};

export default BurgerMenuContent;
