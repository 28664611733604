import { createTheme } from '@mui/material';

export const base = {
  breakpoint: {
    EXTRA_LARGE: 'max-width: 1440px',
    LARGE: 'max-width: 1200px',
    XXL: 'max-width: 1024px',
    XL: 'max-width: 992px',
    L: 'max-width: 768px',
    M: 'max-width: 425px',
    S: 'max-width: 375px'
  },
  fontSize: {
    XXL: '24px',
    XL: '20px',
    L: '18px',
    M: '16px',
    S: '14px',
    XS: '13px',
    XXS: '12px'
  },
  lineHeight: {
    XXL: '32px',
    XL: '26px',
    L: '24px',
    M: '22px',
    S: '20px',
    XS: '18px',
    XXS: '16px'
  },
  fontWeight: {
    XL: '700',
    L: '500',
    M: '400'
  },
  fontFamily: 'DM sans, sans-serif'
};

export const light = {
  main: {
    primary: '#F209A1',
    secondary: '#FF6DB4',
    dark: '#c50061'
  },
  text: {
    primary: {
      main: '#92959D',
      dark: '#242731',
      black: '#333333',
      strongDarkBlue: '#0F0F29',
      lightDark: '#494949',
      light: '#B6B8B9',
      lightGray: '#C8CFE1',
      darkGray: '#868686',
      silverGray: '#999999',
      description: '#919EAB'
    },
    secondary: {
      main: '#007AFF',
      light: '#98C1FF',
      lightBlue: '#4B93FF'
    }
  },
  svg: {
    dark: '#00051D'
  },
  border: {
    gray: '#D9D9D9'
  },
  background: {
    primary: '#F8FAFF',
    outline: '#F2F2F2',
    secondary: '#FFFFFF',
    lightWhite: '#F2F5FF',
    green: '#53AF31',
    red: '#FB3E3F',
    orange: '#FF8E4F',
    gray: '#F9F9F9',
    black: '#000000',
    darkGray: '#637381',
    blue: '#4B5AE5'
  }
};

export const muiLight = createTheme({
  palette: {
    primary: {
      main: light.main.primary,
      light: light.main.secondary,
      dark: light.main.dark
    }
  }
});
