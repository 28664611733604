import React from 'react';
import { useApolloClient } from '@apollo/client';

import * as S from './styled';
import { LS } from '../../../utils';
import power from '../../../assets/img/LoginPowerStation.png';
import { LogoIcon } from '../../../components/Icons';
import { removeRefreshToken, removeToken } from '../../../utils/localStorage';

const VerifyRoute = ({ component }) => {
  const { isToken } = LS;
  const isAuth = isToken();
  const client = useApolloClient();

  const logout = () => {
    removeToken();
    removeRefreshToken();
    client.clearStore();
  };

  if (isAuth) {
    logout();
  }

  return (
    <S.Wrapper>
      <S.ImageBlock>
        <LogoIcon />
        <S.Image src={power} alt="power" />
      </S.ImageBlock>

      <S.Content>{component}</S.Content>
    </S.Wrapper>
  );
};

export default VerifyRoute;
