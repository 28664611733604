import React, { useState, useEffect } from 'react';
import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

import * as S from './styled';
import CommonModalWithHeader from '../../../CommonModalWithHeader';
import Checkbox from '../../../Checkbox';
import { CopyIcon, RemoveIcon } from '../../../../Icons';
import { useMutation, useQuery } from '@apollo/client';
import {
  ASSIGN_CONTRACT_TO_CONNECTOR,
  DELETE_CONTRACT,
  GET_CONTRACT_BY_ID,
  REMOVE_CONTRACT_FROM_CONNECTOR
} from '../../../../../apollo/contracts';
import WarningModal from '../../../WarningModal';
import CommonModal from '../../../CommonModal';
import { GET_CONNECTOR_BY_ID } from '../../../../../apollo/connector';
import { ALERT_TYPES, USER_ACTION_TYPES } from '../../../../../enums';

const AssignedUsers = ({
  handlerChangeModal,
  closeModal,
  handlerActions,
  activeUsers,
  siteValue,
  connectorValue,
  handlerRefetchContract,
  cardPage = false
}) => {
  const { t } = useTranslation();
  const { data, loading } = useQuery(GET_CONTRACT_BY_ID, {
    variables: {
      contractFilter: {
        siteId: +siteValue?.id
      }
    }
  });

  const [assignContractToConnector] = useMutation(ASSIGN_CONTRACT_TO_CONNECTOR, {
    refetchQueries: [
      {
        query: GET_CONNECTOR_BY_ID,
        variables: {
          filter: {
            evseId: connectorValue?.evseId
          }
        }
      }
    ]
  });
  const [removeContractFromConnector] = useMutation(REMOVE_CONTRACT_FROM_CONNECTOR, {
    refetchQueries: [
      {
        query: GET_CONNECTOR_BY_ID,
        variables: {
          filter: {
            evseId: connectorValue?.evseId
          }
        }
      }
    ]
  });

  const [deleteContract] = useMutation(DELETE_CONTRACT, {
    refetchQueries: [
      {
        query: GET_CONNECTOR_BY_ID,
        variables: {
          filter: {
            evseId: connectorValue?.evseId
          }
        }
      },
      {
        query: GET_CONTRACT_BY_ID,
        variables: {
          contractFilter: {
            siteId: +siteValue?.id
          }
        }
      }
    ]
  });

  const [checkboxData, setCheckboxData] = useState([]);
  const [isOpenWarningModal, setIsOpenWarningModal] = useState(false);
  const [deletedUser, setDeletedUser] = useState(null);

  const closeWarningModal = () => setIsOpenWarningModal(false);

  const handlerOpenWarningModal = (user) => {
    setIsOpenWarningModal(true);
    setDeletedUser(user);
  };

  useEffect(() => {
    const checkedData = data?.contracts?.map((item) => {
      const isChecked = activeUsers?.find((user) => user.evcoId === item.evcoId);
      return { ...item, checked: !!isChecked };
    });

    setCheckboxData(checkedData);
  }, [data?.contracts]);

  const handlerChecked = (value) => {
    const variables = {
      contractConnector: {
        evcoId: value,
        evseId: connectorValue?.evseId
      }
    };

    const isChecked = checkboxData?.find((item) => item.evcoId === value);

    const currentData = checkboxData?.map((item) => {
      if (item.evcoId === isChecked?.evcoId && item.checked) {
        removeContractFromConnector({ variables });
        if (cardPage) {
          handlerRefetchContract();
        }
        item.checked = false;
      } else if (item.evcoId === isChecked?.evcoId && !item.checked) {
        assignContractToConnector({ variables });
        if (cardPage) {
          handlerRefetchContract();
        }
        item.checked = true;
      }

      return item;
    });

    setCheckboxData(currentData);
  };

  const deleteUser = async () => {
    try {
      const params = {
        variables: {
          filter: {
            evcoId: deletedUser?.evcoId
          }
        }
      };

      await deleteContract(params);
      handlerActions(t('modal.user_delete_success'));
    } catch (e) {
      handlerActions(e?.response?.data?.message, ALERT_TYPES.ERROR);
    } finally {
      closeWarningModal();
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <CommonModalWithHeader
      title={t('modal.assigned_user')}
      subtitle={t('modal.available_user_explain')}
      buttonText={t('modal.add_new_user')}
      assigned={true}
      onClose={closeModal}
      buttonFunc={() => handlerChangeModal(USER_ACTION_TYPES.ADD_USER)}>
      <CommonModal open={isOpenWarningModal} onClose={closeWarningModal}>
        <WarningModal
          onClose={closeWarningModal}
          handlerProcessedClick={deleteUser}
          description={t('modal.delete_description')}
        />
      </CommonModal>

      <S.CheckboxWrapper>
        {checkboxData?.length ? (
          checkboxData?.map((item) => (
            <S.CheckboxItem key={item?.evcoId} active={item.checked || false}>
              <Checkbox
                value={item?.evcoId}
                label={item?.name}
                checked={item.checked}
                onChecked={handlerChecked}
              />
              <span>
                <CopyIcon onClick={() => handlerChangeModal(USER_ACTION_TYPES.EDIT, item)} />
              </span>
              <span onClick={() => handlerOpenWarningModal(item)}>
                <RemoveIcon />
              </span>
            </S.CheckboxItem>
          ))
        ) : (
          <p>{t('modal.no_contracts')}...</p>
        )}
      </S.CheckboxWrapper>
    </CommonModalWithHeader>
  );
};

export default AssignedUsers;
