import React from 'react';
import { Navigate } from 'react-router-dom';

import * as S from './styled';
import { LS } from '../../../utils';
import power from '../../../assets/img/LoginPowerStation.png';
import { LogoIcon } from '../../../components/Icons';

const PublicRoute = ({ component }) => {
  const { isToken } = LS;
  const isAuth = isToken();

  if (isAuth) {
    return <Navigate to={'/'} />;
  }

  return (
    <S.Wrapper>
      <S.ImageBlock>
        <LogoIcon />
        <S.Image src={power} alt="power" />
      </S.ImageBlock>

      <S.Content>{component}</S.Content>
    </S.Wrapper>
  );
};

export default PublicRoute;
