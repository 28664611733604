import React from 'react';
import styled from 'styled-components';

import exportImg from '../../../../assets/img/csv-file-icon-14.jpg';

const ExportButton = ({ onToggle, buttonRef }) => {
  return (
    <ExportWrapper ref={buttonRef} onClick={onToggle}>
      <ExportImage src={exportImg} alt="export" />
    </ExportWrapper>
  );
};

const ExportWrapper = styled.div``;

const ExportImage = styled.img`
  width: 40px;
  height: 40px;
  object-fit: cover;
  cursor: pointer;
`;

export default ExportButton;
