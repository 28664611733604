import styled from 'styled-components';
import { subTitleText, titleText } from '../../styles/texts';
import Select from '../../components/UI/select';
import { motion } from 'framer-motion';

const Wrapper = styled(motion.div)`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (${({ theme }) => theme.base.breakpoint.XL}) {
    padding: 0 16px;
  }
`;

const Container = styled.div`
  max-width: 1340px;
  width: 100%;
`;

const Info = styled.div`
  margin-top: 24px;
  margin-bottom: 14px;

  @media (${({ theme }) => theme.base.breakpoint.XL}) {
    margin-top: 46px;
  }
`;

const SelectStyle = styled(Select)`
  max-width: 338px;
`;

const InfoTitle = styled.p`
  ${titleText};
  margin-bottom: 14px;

  @media (${({ theme }) => theme.base.breakpoint.XL}) {
    margin-bottom: 4px;
    font-size: 24px;
    line-height: 29px;
    font-weight: bold;
  }
`;

const InfoSubtitle = styled.p`
  ${subTitleText};
  color: ${({ theme }) => theme.color.text.primary.darkGray};
`;

export { Wrapper, Container, Info, InfoTitle, InfoSubtitle, SelectStyle };
