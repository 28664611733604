import styled from 'styled-components';
import TextField from '@mui/material/TextField';

const CustomInput = styled(TextField)`
  width: 100%;
  margin-bottom: 15px;

  div {
    border-radius: 8px;
  }
`;

export { CustomInput };
