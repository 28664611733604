import React from 'react';
import { createRoot } from 'react-dom/client';
import { StyledEngineProvider } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';

import App from './App';
import { ThemeProvider } from 'styled-components';
import { ThemeProvider as ThemeMuiProvider } from '@mui/material';
import GlobalStyle from './styles/globalStyles';
import { translator } from './services';
import { base, light, muiLight } from './styles/themes';
import client from './apollo/client';

const theme = { base, color: { ...light } };

translator.start();

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <React.Suspense fallback="Loading...">
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <ThemeMuiProvider theme={muiLight}>
            <ApolloProvider client={client}>
              <GlobalStyle />
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </ApolloProvider>
          </ThemeMuiProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </React.Suspense>
  </React.StrictMode>
);
