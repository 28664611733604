import { gql } from '@apollo/client';

export const GET_ALL_SITES = gql`
  query GetSites {
    sites {
      id
      name
      tariff {
        pricePerKwH
        pricePerMinute
        freePeriod
        maxBlockPrice
        priceAdhocPerKwH
        priceAdhocPerMinute
        freeAdhocPeriod
        maxAdhocBlockPrice
      }
    }
  }
`;

export const UPDATE_SITE_SETTINGS = gql`
  mutation updateSiteSettings($site: UpdateSiteInput!, $filter: SiteFilter!) {
    updateSites(site: $site, filter: $filter)
  }
`;
