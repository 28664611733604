import React from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './styled';
import CommonModalWithHeader from '../../../CommonModalWithHeader';
import Input from '../../../Input';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { api } from '../../../../../services';
import { ALERT_TYPES, ROLES_TYPES, USER_ACTION_TYPES } from '../../../../../enums';

const AddUsers = ({ handlerChangeModal, handlerActions, siteValue }) => {
  const { t } = useTranslation();

  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email').required(t('auth.email_required')),
    fullName: Yup.string().required(t('auth.name_required'))
  });

  const submitForm = async (value) => {
    const data = {
      fullName: value?.fullName,
      email: value?.email,
      siteId: siteValue?.id,
      contractName: value?.fullName,
      role: ROLES_TYPES.USER
    };

    const contract = {
      name: value?.fullName,
      email: value?.email
    };

    try {
      const response = await api.invitation.inviteUser(data);
      contract.evcoId = response?.data?.evcoId;
      handlerActions(response?.data?.message);
      handlerChangeModal(USER_ACTION_TYPES.EDIT, contract);
    } catch (e) {
      handlerActions(e?.response?.data?.message, ALERT_TYPES.ERROR);
    }
  };

  const formik = useFormik({
    initialValues: validationSchema.cast(),
    validationSchema: validationSchema,
    validateOnChange: false,
    validateOnBlur: true,
    validateOnSubmit: true,
    onSubmit: submitForm
  });

  return (
    <CommonModalWithHeader
      title={t('modal.add_new_user')}
      subtitle={t('modal.added_explain_text')}
      buttonText={t('modal.submit')}
      onClose={() => handlerChangeModal(USER_ACTION_TYPES.ASSIGNED)}
      buttonFunc={formik.handleSubmit}>
      <S.Form>
        <Input
          label={t('auth.email_address')}
          id="email"
          name="email"
          type="email"
          onChange={formik.handleChange}
          value={formik.values.email || ''}
          onBlur={formik.handleBlur}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />

        <Input
          label={t('auth.full_name')}
          id="fullName"
          name="fullName"
          type="text"
          onChange={formik.handleChange}
          value={formik.values.fullName || ''}
          onBlur={formik.handleBlur}
          error={formik.touched.fullName && Boolean(formik.errors.fullName)}
          helperText={formik.touched.fullName && formik.errors.fullName}
        />

        <Input
          label={t('auth.site')}
          id="site"
          name="site"
          type="text"
          onChange={formik.handleChange}
          disabled={true}
          value={siteValue?.name}
          onBlur={formik.handleBlur}
        />

        <Input
          label={t('auth.role')}
          id="role"
          name="role"
          type="text"
          onChange={formik.handleChange}
          disabled={true}
          value={ROLES_TYPES.USER}
          onBlur={formik.handleBlur}
        />
        {/*<Input label={t('modal.email')} />*/}
      </S.Form>
    </CommonModalWithHeader>
  );
};

export default AddUsers;
