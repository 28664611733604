import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';

import routes from './router/pages/routes';
import { PrivateRoute, PublicRoute, VerifyRoute } from './router/components';
import {
  LoginPage,
  AuthPage,
  OverviewWallboxes,
  ChargingHistory,
  SettingsPage,
  AdaptiveCardItem,
  ResetPasswordPage,
  VerificationPage,
  AutoVerificationUser,
  ResetPasswordConfirm,
  UserSettingsPage,
  ActivateInvitationUser
} from './pages';

const App = () => {
  return (
    <AnimatePresence exitBeforeEnter>
      <Routes>
        <Route path={routes.LOGIN} element={<PublicRoute component={<LoginPage />} />} />
        <Route path={routes.SIGN_IN} element={<PublicRoute component={<AuthPage />} />} />
        <Route
          path={routes.ACTIVATE}
          element={<PublicRoute component={<ActivateInvitationUser />} />}
        />
        <Route
          path={routes.VERIFY_PAGE}
          element={<PublicRoute component={<VerificationPage />} />}
        />
        <Route
          path={routes.VERIFY_PAGE_ID}
          element={<VerifyRoute component={<AutoVerificationUser />} />}
        />
        <Route
          path={routes.RESET_PASSWORD}
          element={<PublicRoute component={<ResetPasswordPage />} />}
        />

        <Route
          path={routes.RESET_PASSWORD_CONFIRM}
          element={<PublicRoute component={<ResetPasswordConfirm />} />}
        />

        <Route path={routes.HOME} element={<PrivateRoute component={<OverviewWallboxes />} />} />
        <Route
          path={routes.CHARGING_HISTORY}
          element={<PrivateRoute component={<ChargingHistory />} />}
        />
        <Route path={routes.SETTINGS} element={<PrivateRoute component={<SettingsPage />} />} />
        <Route
          path={routes.USER_SETTINGS}
          element={<PrivateRoute component={<UserSettingsPage />} />}
        />
        <Route
          path={routes.CHARGE_ITEM}
          element={<PrivateRoute component={<AdaptiveCardItem />} />}
        />
      </Routes>
    </AnimatePresence>
  );
};

export default App;
