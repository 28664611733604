import styled from 'styled-components';

const SidebarContainer = styled.div`
  max-width: 235px;
  width: 100%;
  height: 100vh;
  border-radius: 0 24px 24px 0;
  box-shadow: 0 0 21px 0 rgba(2, 0, 85, 0.05);
  margin-bottom: 96px;
  display: flex;
  flex-direction: column;
  padding: 50px 0 54px 0;
  z-index: 622;
  @media (${({ theme }) => theme.base.breakpoint.XL}) {
    display: none;
  } ;
`;

const SidebarLogo = styled.div`
  margin: 0 0 130px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export { SidebarContainer, SidebarLogo };
