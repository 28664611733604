import { gql } from '@apollo/client';

export const ALL_CONTRACTS = gql`
  query GetAllUsers {
    contracts {
      name
      evcoId
      site {
        id
        name
      }
      user {
        email
      }
      tenant
      tokens {
        print
        deviceType
        id
      }
      connectors {
        evseId
        name
      }
    }
  }
`;

export const ADD_NEW_CONTRACT = gql`
  mutation createContract($contract: CreateContractInput!) {
    createContract(contract: $contract) {
      name
      evcoId
      tokens {
        id
        deviceType
        print
      }
    }
  }
`;

export const EDIT_CONTRACT = gql`
  mutation updateContracts($contract: UpdateContractInput!, $filter: ContractFilter!) {
    updateContracts(contract: $contract, filter: $filter)
  }
`;

export const ASSIGN_CONTRACT_TO_CONNECTOR = gql`
  mutation assignContractToConnector($contractConnector: ContractFilter!) {
    assignContractToConnector(Contract_Connector: $contractConnector)
  }
`;

export const REMOVE_CONTRACT_FROM_CONNECTOR = gql`
  mutation removeContractFromConnector($contractConnector: ContractFilter!) {
    removeContractFromConnector(Contract_Connector: $contractConnector)
  }
`;

export const DELETE_CONTRACT = gql`
  mutation deleteContract($filter: ContractFilter!) {
    deleteContract(filter: $filter)
  }
`;

export const GET_CONTRACT_BY_ID = gql`
  query getContractByID($contractFilter: ContractsFilter) {
    contracts(contractFilter: $contractFilter) {
      name
      evcoId
      user {
        id
        email
        fullName
      }
    }
  }
`;

export const GET_USERS_CONTRACT = gql`
  query Users($userFilter: UserFilter) {
    users(userFilter: $userFilter) {
      contracts {
        name
        evcoId
      }
    }
  }
`;
