import React from 'react';

import * as S from './styled';
import Sidebar from '../../../components/UI/Sidebar';
import { isToken } from '../../../utils/localStorage';
import { Navigate } from 'react-router-dom';
import { UserProvider } from '../../../context';

const PrivateRoute = ({ component }) => {
  const isAuth = isToken();

  if (!isAuth) {
    return <Navigate to={'/login'} />;
  }

  return (
    <UserProvider>
      <S.Layout>
        <Sidebar />
        <S.Content>{component}</S.Content>
      </S.Layout>
    </UserProvider>
  );
};

export default PrivateRoute;
