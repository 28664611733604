import styled from 'styled-components';

const CheckboxWrapper = styled.div`
  max-height: 300px;
  overflow-y: scroll;
`;

const CheckboxItem = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  padding: 18px;
  margin-bottom: 12px;
  margin-right: 0;
  border-radius: 8px;
  transition: 0.3s all;
  z-index: 1;

  ${({ theme, active }) =>
    active &&
    `
    background: ${theme.color.background.primary};
  `};

  &:hover {
    background: ${({ theme }) => theme.color.background.primary};
  }

  span {
    display: flex;
    align-items: center;
  }

  svg {
    position: relative;
    margin-right: 10px;
    cursor: pointer;
    transition: 0.3s all;
    z-index: 10;

    &:hover {
      transform: scale(1.1);
    }
  }
`;

export { CheckboxWrapper, CheckboxItem };
