import axios from 'axios';
import { connection, token } from './interceptors';

const instance = axios.create({
  baseURL: process.env.REACT_APP_REST_URL,
  headers: {
    'Content-Type': 'application/json'
  },
  data: {},
  timeout: 180000
});

token(instance);
connection(instance);

export default instance;
