import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import * as S from '../../styled';
import Input from '../../../../components/UI/Input';
import CommonButton from '../../../../components/UI/button';
import { useUserInfo } from '../../../../context';
import { api } from '../../../../services';
import { ALERT_TYPES } from '../../../../enums';

const UserSettings = ({ handlerActions }) => {
  const { t } = useTranslation();
  const { info, updateInfo } = useUserInfo();

  const validationSchema = Yup.object().shape({
    fullName: Yup.string().required(t('auth.name_required')).default('')
  });

  const submitForm = async (values) => {
    try {
      const { data } = await api.user.updateUserInfo({ fullName: values?.fullName });
      updateInfo(data);
      handlerActions(t('settings.user_info_update'));
    } catch (e) {
      handlerActions(e?.response?.data?.errors?.[0]?.msg, ALERT_TYPES.ERROR);
    }
  };

  const formik = useFormik({
    initialValues: { fullName: info?.fullName, email: info?.email },
    validationSchema: validationSchema,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: true,
    validateOnSubmit: true,
    onSubmit: submitForm
  });

  return (
    <S.FormBlock>
      <Input
        label={t('auth.name')}
        id="fullName"
        name="fullName"
        type="text"
        onChange={formik.handleChange}
        value={formik.values.fullName || ''}
        onBlur={formik.handleBlur}
        helperText={formik.touched.name && formik.errors.name}
      />
      <Input
        label={t('auth.email_address')}
        id="email"
        name="email"
        type="email"
        disabled={true}
        onChange={formik.handleChange}
        value={formik.values.email || ''}
        onBlur={formik.handleBlur}
        helperText={formik.touched.email && formik.errors.email}
      />

      <CommonButton text={'Submit'} onClick={formik.handleSubmit} />
    </S.FormBlock>
  );
};

export default UserSettings;
