import styled from 'styled-components';
import { textMedium, textSmall, textXSmallPrimaryMain } from '../../../styles/texts';

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 18px 32px 18px 32px;
  background: ${({ theme }) => theme.color.background.secondary};
  border-radius: 20px;
  ${({ showTab }) => !showTab && `margin-left: auto;`}

  @media (${({ theme }) => theme.base.breakpoint.XL}) {
    padding: 10px;
  }
`;

const ImageContainer = styled.div`
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;

  svg {
    width: 100%;
    height: 100%;
  }

  @media (${({ theme }) => theme.base.breakpoint.XL}) {
    width: 24px;
    height: 24px;
  }
`;

// const Image = styled.img`
//   width: 30px;
//   height: 30px;
//   object-fit: cover;
//
//   @media (${({ theme }) => theme.base.breakpoint.XL}) {
//     width: 18px;
//     height: 18px;
//   }
// `;

const ProfileContainer = styled.div`
  //margin-right: 100px;

  @media (${({ theme }) => theme.base.breakpoint.XL}) {
    display: none;
  }
`;

const ProfileName = styled.p`
  ${textMedium};
  font-weight: 700;
`;

const ProfileEmail = styled.p`
  ${textXSmallPrimaryMain};
`;

const ProfileIconsBlock = styled.div`
  display: flex;
  align-items: center;
`;

const IconItem = styled.div`
  padding: 14px;
  width: 48px;
  height: 48px;
  border: 1px solid ${({ theme }) => theme.color.background.outline};
  border-radius: 50%;
  cursor: pointer;
  transition: 0.3s all;

  &:first-child {
    margin-right: 16px;
  }

  svg {
    width: 100%;
    height: 100%;
  }

  &:hover {
    background: ${({ theme }) => theme.color.background.outline};
  }

  @media (${({ theme }) => theme.base.breakpoint.XL}) {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    width: 24px;
    height: 24px;

    svg {
      width: 8px;
      height: 8px;
    }

    &:first-child {
      margin-right: 8px;
    }
  }
`;

const ArrowBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  cursor: pointer;
  transition: 0.3s all;

  &:hover {
    opacity: 0.5;
  }
`;

const DropDownBlock = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  background: ${({ theme }) => theme.color.background.secondary};
  width: 80%;
  cursor: pointer;
  border-radius: 20px;
  transform: translateY(100%);
  z-index: 50;

  @media (${({ theme }) => theme.base.breakpoint.XL}) {
    width: 140%;
  }
`;

const DropDownMenu = styled.ul`
  padding: 10px 0;
  list-style: none;
  text-align: center;
`;

const DropDownItem = styled.li`
  padding: 10px 20px;
  display: flex;
  align-items: center;
  ${textSmall};
  border-bottom: 1px solid ${({ theme }) => theme.color.background.gray};
  transition: 0.3s all;

  &:hover {
    background: ${({ theme }) => theme.color.background.gray};
  }

  svg {
    margin-right: 10px;
    width: 20px;
    height: 20px;

    @media (${({ theme }) => theme.base.breakpoint.XL}) {
      width: 16px;
      height: 16px;
    }
  }

  @media (${({ theme }) => theme.base.breakpoint.XL}) {
    padding: 10px;
  }

  &:last-child {
    border-bottom: none;
  }
`;

export {
  Container,
  ImageContainer,
  // Image,
  ProfileContainer,
  ProfileName,
  ProfileEmail,
  ProfileIconsBlock,
  IconItem,
  ArrowBlock,
  DropDownBlock,
  DropDownMenu,
  DropDownItem
};
