import styled from 'styled-components';
import car from '../../../assets/img/LoginBackgroundImage.png';

const Wrapper = styled.div`
  display: flex;

  @media (${({ theme }) => theme.base.breakpoint.XL}) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const ImageBlock = styled.div`
  position: relative;
  width: 40%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-image: url(${car});
  background-position: center;

  svg {
    position: absolute;
    top: 52px;
    left: 58px;

    @media (${({ theme }) => theme.base.breakpoint.XL}) {
      margin: 64px auto;
      width: 169px;
      height: 69px;
      position: relative;
      top: auto;
      left: auto;
      display: flex;
      justify-content: center;
    }
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    background: linear-gradient(89.94deg, #325362 0.05%, rgba(217, 217, 217, 0) 120.1%);

    @media (${({ theme }) => theme.base.breakpoint.XL}) {
      display: none;
    }
  }

  @media (${({ theme }) => theme.base.breakpoint.XL}) {
    width: 100%;
    background: none;
    min-height: auto;
  }
`;

const Image = styled.img`
  position: relative;
  max-height: 634px;
  max-width: 634px;

  @media (${({ theme }) => theme.base.breakpoint.XL}) {
    display: none;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 60%;

  @media (${({ theme }) => theme.base.breakpoint.XL}) {
    width: 100%;
  }
`;

export { Wrapper, ImageBlock, Image, Content };
