import { gql } from '@apollo/client';

export const GET_SITE_INFO = gql`
  query getSiteInfo($filter: SiteFilter!) {
    site(filter: $filter) {
      id
      name
      contracts {
        name
        evcoId
      }
    }
  }
`;
