import React, { useEffect, useState, useRef, useCallback } from 'react';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import {
  GroupingState,
  IntegratedGrouping,
  SummaryState,
  IntegratedSummary,
  DataTypeProvider
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableGroupRow,
  ExportPanel,
  Toolbar
} from '@devexpress/dx-react-grid-material-ui';
import saveAs from 'file-saver';

import * as S from './styled';
import station from '../../../assets/img/stationImg.png';
import { ExportButton } from './custom';
import moment from 'moment';
import {roundTo1Decimal, roundTo2Decimal, sortByField} from '../../../utils';
import { CHART_TYPES } from '../../../enums';
import { useUserInfo } from '../../../context';
import {charge_amount_formating, cost_formating} from "../../../utils/custom_formating";

const TableGrid = ({ value, type }) => {
  const { isSiteAdmin } = useUserInfo();
  const exporterRef = useRef(null);

  const [rows, setRows] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [currencyColumnsCost] = useState(['cost', 'voltage']);
  const [groupSummaryItems, setGroupSummaryItems] = useState([
    { columnName: 'info', type: 'count' },
    { columnName: 'date', type: 'count' },
    { columnName: 'duration', type: 'sum' },
    { columnName: 'cost', type: 'sum', alignByColumn: false, valueFormat:{ precision: 2 } },
    { columnName: 'voltage', type: 'sum', alignByColumn: false, valueFormat:{ precision: 1 } }
  ]);
  const [tableColumnExtensions, setTableColumnsExtensions] = useState([
    { columnName: 'info', width: '25%' },
    { columnName: 'date', width: '25%' },
    { columnName: 'duration', width: 'auto' },
    { columnName: 'cost', width: 'auto' },
    { columnName: 'voltage', width: 'auto' }
  ]);
  const [expendedGroups, setExpendedGroups] = useState([]);

  useEffect(() => {
    const sourceData = [
      { name: 'info', title: 'Connector' },
      { name: 'date', title: 'Date' },
      { name: 'duration', title: 'Duration' },
      { name: 'cost', title: 'Costs [€]' },
      { name: 'voltage', title: 'Amount [kWh]' }
    ];

    if (isSiteAdmin) {
      setGroupSummaryItems((prev) => [...prev, { columnName: 'user', type: 'count' }]);
      setTableColumnsExtensions((prev) => [...prev, { columnName: 'user', width: 'auto' }]);
      sourceData.push({ name: 'user', title: 'User' });
    }

    setDataSource(sourceData);

    if (value) {
      const data = [];
      const expandData = [];

      value = [...value]?.sort(sortByField('startTime'));

      value?.forEach((item) => {
        const objData = {};
        const startTime = moment(item.startTime).format('DD.MM.YYYY hh:mm');

        if (type === 'date') {
          expandData.push('date', startTime);
        }

        const duration = moment.duration(item.charging_time);

        objData['info'] = item.connector_name;
        objData['evseId'] = item.evse_id;
        objData['date'] = startTime;
        objData['duration'] = `${duration.hours() < 10 ? '0' : ''}${duration.hours()}:${
          duration.minutes() < 10 ? '0' : ''
        }${duration.minutes()}:${duration.seconds() < 10 ? '0' : ''}${duration.seconds()}`;
        objData['cost'] = cost_formating(item.current_cost);
        objData['voltage'] = charge_amount_formating(item.amount);

        if (isSiteAdmin) {
          objData['user'] = item?.contract_name;
        }

        data.push(objData);
      });

      if (type === 'date') {
        setExpendedGroups(expandData);
      }

      setRows(data);
    }

    setGroupSummaryItems([
      { columnName: 'cost', type: 'sum', alignByColumn: type === 'user' },
      { columnName: 'voltage', type: 'sum', alignByColumn: type === 'user' }
    ]);
  }, [value, type]);

  const Cell = (props) => {
    const { column, row } = props;

    switch (column?.name) {
      case 'info': {
        return (
          <S.TableFirstColumn>
            <S.ListImageBlock>
              <S.ListImage src={station} alt={'stations'} />
            </S.ListImageBlock>
            <S.CarTitleBlock>
              <p>{row?.info}</p>
              <p>{row?.evseId}</p>
            </S.CarTitleBlock>
          </S.TableFirstColumn>
        );
      }

      case 'user': {
        return (
          <Table.Cell {...props}>
            <S.TableUserColumn>{row?.user}</S.TableUserColumn>
          </Table.Cell>
        );
      }

      case 'cost': {
        return <Table.Cell {...props}>€{row?.cost}</Table.Cell>;
      }

      case 'voltage': {
        return <Table.Cell {...props}>{row?.voltage} kWh</Table.Cell>;
      }

      default: {
        return <Table.Cell {...props} />;
      }
    }
  };

  const handlerExpandGroup = (group) => {
    setExpendedGroups(group);
  };

  const CurrencyFormatter = ({ value, ...props }) => {
    if (props.column.name === 'voltage') {
      return roundTo1Decimal(parseFloat(value));
    } else {
      if (props.column.name === 'cost'){
         return roundTo2Decimal(parseFloat(value))
      } else {
        return value; //.toLocaleString('en-US', { style: 'currency', currency: 'EUR' });
      }
    }
  };

  const CurrencyTypeProvider = (props) => (
    <DataTypeProvider formatterComponent={CurrencyFormatter} {...props} />
  );

  const onSave = (workbook) => {
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
    });
  };

  const GroupCellContent = ({ column, row }) => {
    if (column.name === 'info') {
      const columnData = rows?.find((item) => item.info === row.value);
      return (
        <S.GroupTitleBlock>
          <p>{columnData?.evseId}</p>&nbsp; - {row?.value}
        </S.GroupTitleBlock>
      );
    }

    return row?.value;
  };

  const startExport = useCallback(() => {
    exporterRef.current.exportGrid();
  }, [exporterRef]);

  return (
    <S.TableWrapper key={type} isUser={type === CHART_TYPES.USER} type={type}>
      {dataSource?.length && (
        <>
          <Grid rows={rows} columns={dataSource}>
            <CurrencyTypeProvider for={currencyColumnsCost} />
            <GroupingState
              grouping={[{ columnName: type }]}
              expandedGroups={expendedGroups}
              onExpandedGroupsChange={handlerExpandGroup}
            />
            <IntegratedGrouping />
            <SummaryState groupItems={groupSummaryItems} />
            <IntegratedSummary />
            <Table cellComponent={Cell} columnExtensions={tableColumnExtensions} />
            <TableHeaderRow />
            <Toolbar />
            <ExportPanel startExport={startExport} toggleButtonComponent={ExportButton} />
            <TableGroupRow contentComponent={GroupCellContent} />
          </Grid>
          <GridExporter
            ref={exporterRef}
            rows={rows}
            grouping={[{ columnName: type }]}
            columns={dataSource}
            onSave={onSave}
          />
        </>
      )}
    </S.TableWrapper>
  );
};

export default TableGrid;
