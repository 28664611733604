import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';

import { api } from '../../services';
import AuthFormBlock from '../../components/UI/AuthFormBlock';
import AlertMessage from '../../components/UI/AlertMessage';

const ActivateInvitationUser = () => {
  const { id } = useParams();

  const [isLoader, setIsLoader] = useState(false);
  const [activateInitialData, setActivateInitialData] = useState({});
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [errorText, setErrorText] = useState('');

  const closeSnackbar = () => {
    setOpenSnackbar(false);
    setErrorText('');
  };

  useEffect(() => {
    if (id) {
      fetchActivateUserData();
    }
  }, [id]);

  const fetchActivateUserData = async () => {
    setIsLoader(true);
    try {
      const response = await api.invitation.getUserInvitation(id);
      setActivateInitialData(response?.data);
    } catch (e) {
      setOpenSnackbar(true);
      setErrorText(e?.response?.data?.message);
    } finally {
      setIsLoader(false);
    }
  };

  if (isLoader) {
    return <CircularProgress />;
  }

  return (
    <>
      <AlertMessage open={openSnackbar} onClose={closeSnackbar} errorText={errorText} />
      {Object.keys(activateInitialData)?.length && (
        <AuthFormBlock isActivate={true} isLogin={false} activateData={activateInitialData} />
      )}
    </>
  );
};

export default ActivateInvitationUser;
