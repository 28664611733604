import React from 'react';

export const ArrowBack = () => (
  <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.08329 9.16671L0.916626 5.00004L5.08329 0.833374"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
