const routes = {
  HOME: '/',
  REGISTER: '/register',
  REGISTER_DETAIL: '/register-detail',
  REGISTER_FINAL: '/register-final',
  CHARGE_MAP: '/charge-map',
  CHARGING_MONITOR: '/charging-monitor',
  CHARGING_HISTORY: '/charging-history',
  SETTINGS: '/settings',
  USER_SETTINGS: '/user-settings',
  ACCOUNT: '/account',
  SUPPORT: '/support',
  CHARGE_ITEM: '/connector/:id&site_id=:siteID',
  LOGIN: '/login',
  ACTIVATE: '/activate/:id',
  SIGN_IN: '/sign-in',
  LOGOUT: '/logout',
  RESET_PASSWORD: '/reset-password',
  RESET_PASSWORD_CONFIRM: '/reset-password/:id',
  VERIFY_PAGE: '/verify-page',
  VERIFY_PAGE_ID: '/verify/:id',
  NOT_FOUND: '/*'
};

export default routes;
