import styled from 'styled-components';

const MainNavigation = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;

  @media (${({ theme }) => theme.base.breakpoint.XL}) {
    height: 80%;
  } ;
`;

const MainNavigationTop = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const MainNavigationBottom = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export { MainNavigation, MainNavigationTop, MainNavigationBottom };
