import styled from 'styled-components';
import { sidebarText } from '../../../../../styles/texts';

const NavBarItem = styled.div`
  width: 100%;
  height: 64px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 37px;
  transition: 0.3s all;

  svg {
    width: 32px;
    height: 32px;
    margin-right: 22px;
  }

  // & p:hover {
  //   color: ${({ theme }) => theme.color.main.primary};
  // }

  &:hover {
    opacity: 0.5;
  }
`;
const ActiveBorder = styled.div`
  position: absolute;
  width: 8px;
  height: 100%;
  border-radius: 15px;
  left: -3px;
  background: ${({ theme }) => theme.color.main.primary};
`;

const NavigationItemText = styled.p`
  ${sidebarText};
  transition: 0.3s;
`;

export { NavBarItem, ActiveBorder, NavigationItemText };
