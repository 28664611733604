import {roundTo1Decimal, roundTo2Decimal} from "./math_util";


export const cost_formating = (num) => {
    return roundTo2Decimal(num) ;
};

export const charge_amount_formating = (num) => {
    const kwh_num = num/1000
    return roundTo1Decimal(kwh_num) ;
};
