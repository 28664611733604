import React from 'react';
import { MenuItem } from '@mui/material';

import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';

import * as S from './styled';

const Select = ({ options, onChange, value, label, ...props }) => {
  return (
    <S.FormControlBlock>
      <InputLabel id="demo-multiple-name-label">{label}</InputLabel>
      <S.StyledSelect
        labelId="demo-multiple-name-label"
        id="demo-multiple-name"
        displayEmpty={true}
        value={value}
        input={<OutlinedInput label={label} />}
        onChange={onChange}
        {...props}>
        {options.map((el, i) => {
          return (
            <MenuItem key={i + el.value} value={el.value}>
              {el.name}
            </MenuItem>
          );
        })}
      </S.StyledSelect>
    </S.FormControlBlock>
  );
};

export default Select;
