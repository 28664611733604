import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import CircularProgress from '@mui/material/CircularProgress';
import { useMutation, useQuery } from '@apollo/client';

import * as S from './styled';
import CommonModalWithHeader from '../../../CommonModalWithHeader';
import Input from '../../../Input';
import { CopyIcon, RemoveIcon } from '../../../../Icons';
import { ALL_CONTRACTS, EDIT_CONTRACT, GET_CONTRACT_BY_ID } from '../../../../../apollo/contracts';
import { ALL_CHARGEPOINT } from '../../../../../apollo/chargingHistory';
import { DELETE_TOKEN, GET_TOKENS } from '../../../../../apollo/token';
import CommonModal from '../../../CommonModal';
import WarningModal from '../../../WarningModal';
import { GET_CONNECTOR_BY_ID } from '../../../../../apollo/connector';
import { ALERT_TYPES, USER_ACTION_TYPES } from '../../../../../enums';

const EditUser = ({
  user,
  handlerChangeModal,
  handlerEditToken,
  handlerCreateToken,
  connectorValue,
  handlerActions,
  siteValue
}) => {
  const { t } = useTranslation();

  const [editContract, { error }] = useMutation(EDIT_CONTRACT, {
    refetchQueries: [
      {
        query: GET_CONTRACT_BY_ID,
        variables: {
          contractFilter: {
            siteId: +siteValue?.id
          }
        }
      },
      {
        query: GET_CONNECTOR_BY_ID,
        variables: {
          filter: {
            evseId: connectorValue?.evseId
          }
        }
      }
    ]
  });
  const [deleteContract, { error: deleteContractError, loading: deleteLoading }] = useMutation(
    DELETE_TOKEN,
    {
      refetchQueries: [
        {
          query: GET_TOKENS,
          variables: {
            tokenFilter: {
              id: null,
              evcoId: user?.evcoId
            }
          }
        }
      ]
    }
  );
  const {
    data: tokenData,
    loading,
    error: tokenError
  } = useQuery(GET_TOKENS, {
    variables: {
      tokenFilter: {
        id: null,
        evcoId: user?.evcoId
      }
    }
  });

  const [isOpenWarningModal, setIsOpenWarningModal] = useState(false);
  const [deletedToken, setDeletedToken] = useState(null);

  const handlerOpenWarningModal = (token) => {
    setIsOpenWarningModal(true);
    setDeletedToken(token);
  };

  const closeWarningModal = () => {
    setIsOpenWarningModal(false);
    setDeletedToken(null);
  };

  const handlerDeletedToken = async () => {
    const variables = {
      tokenId: deletedToken?.id
    };

    await deleteContract({ variables });
    closeWarningModal();

    handlerActions(t('modal.token_delete_success'));
  };

  const validationSchema = Yup.object({
    fullName: Yup.string().required(t('auth.name_required'))
  });

  const submitForm = async (value) => {
    const variables = {
      contract: {
        name: value?.fullName
      },
      filter: {
        evcoId: user?.evcoId
      }
    };

    await editContract({
      variables
    });

    handlerActions(t('modal.user_edit_success'));
    handlerChangeModal(USER_ACTION_TYPES.ASSIGNED);
  };

  const formik = useFormik({
    initialValues: { fullName: user?.name, email: user?.email || user?.user?.email },
    validationSchema: validationSchema,
    validateOnChange: false,
    validateOnBlur: true,
    validateOnSubmit: true,
    onSubmit: submitForm
  });

  if (error) {
    handlerActions(error.message, ALERT_TYPES.ERROR);
  } else if (deleteContractError) {
    handlerActions(deleteContractError.message, ALERT_TYPES.ERROR);
  } else if (tokenError) {
    handlerActions(tokenError.message, ALERT_TYPES.ERROR);
  }

  if (loading || deleteLoading) {
    return <CircularProgress />;
  }

  return (
    <CommonModalWithHeader
      title={t('modal.edit_user')}
      subtitle={t('modal.edit_explain_text')}
      buttonText={t('modal.submit')}
      onClose={() => handlerChangeModal(USER_ACTION_TYPES.ASSIGNED)}
      buttonFunc={formik.handleSubmit}>
      <CommonModal open={isOpenWarningModal} onClose={closeWarningModal}>
        <WarningModal
          onClose={closeWarningModal}
          handlerProcessedClick={handlerDeletedToken}
          description={t('modal.delete_token_description')}
        />
      </CommonModal>
      <S.Form>
        <Input
          label={t('auth.full_name')}
          id="fullName"
          name="fullName"
          type="text"
          onChange={formik.handleChange}
          value={formik.values.fullName || user?.name}
          onBlur={formik.handleBlur}
          error={formik.touched.fullName && Boolean(formik.errors.fullName)}
          helperText={formik.touched.fullName && formik.errors.fullName}
        />

        <Input
          label={t('auth.email_address')}
          id="email"
          name="email"
          type="email"
          disabled={true}
          value={formik.values.email || user?.user?.email}
        />
      </S.Form>

      <S.TokenBlock>
        {t('modal.token')}
        <S.TokenAddButton
          text={t('modal.add_new')}
          onClick={() => handlerCreateToken(USER_ACTION_TYPES.ADD_TOKEN, user)}
        />
      </S.TokenBlock>

      <S.TokenList>
        {user?.evcoId && tokenData?.tokens?.length
          ? tokenData?.tokens?.map((item) => (
              <S.TokenItemBlock key={item.id}>
                <div>
                  <S.TokenItemTitle>{item.print}</S.TokenItemTitle>
                  <S.TokenItemSubTitle>
                    <p>{item.id}</p>
                    <p>{item.deviceType}</p>
                  </S.TokenItemSubTitle>
                </div>

                <S.TokenItemIcons>
                  <span onClick={() => handlerEditToken(USER_ACTION_TYPES.EDIT_TOKEN, item, user)}>
                    <CopyIcon />
                  </span>
                  <span onClick={() => handlerOpenWarningModal(item)}>
                    <RemoveIcon />
                  </span>
                </S.TokenItemIcons>
              </S.TokenItemBlock>
            ))
          : null}
      </S.TokenList>
    </CommonModalWithHeader>
  );
};

export default EditUser;
