import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';

import * as S from './styled';
import CommonButton from '../../../../components/UI/button';
import { GET_ALL_SITES, UPDATE_SITE_SETTINGS } from '../../../../apollo/settings';
import { validateTariffValues } from '../../../../utils';

const GeneralSettings = () => {
  const { data, loading} = useQuery(GET_ALL_SITES);

  const [updateSiteSettings, { loading: updateLoading }] = useMutation(UPDATE_SITE_SETTINGS, {
    refetchQueries: [{ query: GET_ALL_SITES }],
    awaitRefetchQueries: true
  });

  const [values, setValues] = useState({});

  if (loading) {
    return <CircularProgress />;
  }

  const handlerInputData = (e, tariff_key) => {
    const currentValue = { ...values };
    const key = tariff_key;
    currentValue[key] = +e.target.value;

    setValues({ ...currentValue });
  };

  const handlerSubmit = async (site) => {
    if (!Object.values(values)?.length) {
      return false;
    }

    const variables = {
      filter: {
        id: site?.id
      },
      site: {
        ...values
      }
    };

    await updateSiteSettings({ variables });
    setValues({});
  };

  if (loading || updateLoading) {
    return <CircularProgress />;
  }

  return (
    <>
      {data?.sites?.length ? (
        data?.sites?.map((item) => (
          <S.Block key={item.id}>
            <S.Title>{item.name}</S.Title>
            <S.SubTitle>Pricing (regular users)</S.SubTitle>
            <S.InputBlock>
              <S.SettingInput
                  label={"pricePerkWh"}
                  defaultValue={item?.tariff?.pricePerKwH}
                  onInput={validateTariffValues}
                  onChange={(e) => handlerInputData(e, "tariff_price_per_kwh")}
              />
              <S.SettingInput
                  label={"pricePerMinute"}
                  defaultValue={item?.tariff?.pricePerMinute}
                  onInput={validateTariffValues}
                  onChange={(e) => handlerInputData(e, "tariff_price_per_minute")}
              />
              <S.SettingInput
                  label={"freePeriodInMinutes"}
                  defaultValue={item?.tariff?.freePeriod}
                  onInput={validateTariffValues}
                  onChange={(e) => handlerInputData(e, "free_period_in_minutes")}
              />
              <S.SettingInput
                  label={"maxBlockPrice"}
                  defaultValue={item?.tariff?.maxBlockPrice}
                  onInput={validateTariffValues}
                  onChange={(e) => handlerInputData(e, "max_block_price")}
              />
            </S.InputBlock>
            <S.SubTitle>Pricing (adhoc payments)</S.SubTitle>
            <S.InputBlock>
              <S.SettingInput
                  label={"priceAdhocPerkWh"}
                  defaultValue={item?.tariff?.priceAdhocPerKwH}
                  onInput={validateTariffValues}
                  onChange={(e) => handlerInputData(e, "price_adhoc_per_kwh")}
              />
              <S.SettingInput
                  label={"priceAdhocPerMinute"}
                  defaultValue={item?.tariff?.priceAdhocPerMinute}
                  onInput={validateTariffValues}
                  onChange={(e) => handlerInputData(e, "price_adhoc_per_minute")}
              />
              <S.SettingInput
                  label={"freeAdhocPeriodInMinutes"}
                  defaultValue={item?.tariff?.freeAdhocPeriod}
                  onInput={validateTariffValues}
                  onChange={(e) => handlerInputData(e, "free_adhoc_period_in_minutes")}
              />
              <S.SettingInput
                  label={"maxAdhocBlockPrice"}
                  defaultValue={item?.tariff?.maxAdhocBlockPrice}
                  onInput={validateTariffValues}
                  onChange={(e) => handlerInputData(e, "max_adhoc_block_price")}
              />
            </S.InputBlock>
            <CommonButton text={'Submit'} onClick={() => handlerSubmit(item)} />
          </S.Block>
        ))
      ) : (
        <p>No sites...</p>
      )}
    </>
  );
};

export default GeneralSettings;
