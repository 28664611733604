import React from 'react';

export const SettingsIcon = ({ active }) => {
  if (active) {
    return (
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M3.86713 10.3462L5.04047 8.31389C5.7338 7.11301 7.28863 6.68664 8.50107 7.38664C10.5911 8.5933 12.2989 7.60861 12.2948 5.18906C12.2943 3.80317 13.4323 2.65884 14.8297 2.66506L17.4874 2.67524C18.7129 2.6592 19.7041 3.66252 19.7201 4.88806L19.7205 5.18078C19.713 7.59366 21.4219 8.58033 23.5194 7.37412L23.773 7.22806C24.8425 6.62918 26.2069 6.98586 26.8058 8.05524L28.1434 10.3517C28.8475 11.5588 28.4255 13.1165 27.225 13.8091C25.1275 15.0153 25.1287 16.9867 27.2187 18.1933C28.4197 18.8867 28.8461 20.4415 28.1461 21.6539L26.9727 23.6861C26.2794 24.8871 24.7245 25.3135 23.5121 24.6135C21.4221 23.4068 19.7142 24.3915 19.7183 26.8109C19.7122 28.2084 18.5809 29.3412 17.1834 29.3351L14.5258 29.3248C13.3003 29.3409 12.3091 28.3376 12.2931 27.112L12.2927 26.8193C12.3002 24.4064 10.5912 23.4197 8.49376 24.626L8.24008 24.772C7.17071 25.3709 5.80624 25.0143 5.20736 23.9448L3.86973 21.6484C3.16561 20.4412 3.58767 18.8836 4.78811 18.1909C6.88555 16.9848 6.8844 15.0135 4.7944 13.8068C3.58196 13.1068 3.1738 11.5471 3.86713 10.3462Z"
          stroke="#F209A1"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.3334 16C20.3334 13.6067 18.3933 11.6666 16.0001 11.6666C13.6068 11.6666 11.6667 13.6067 11.6667 16C11.6667 18.3932 13.6068 20.3333 16.0001 20.3333C18.3933 20.3333 20.3334 18.3932 20.3334 16Z"
          stroke="#F209A1"
          strokeWidth="2"
        />
      </svg>
    );
  }

  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.86713 10.3462L5.04047 8.31389C5.7338 7.11301 7.28863 6.68664 8.50107 7.38664C10.5911 8.5933 12.2989 7.60861 12.2948 5.18906C12.2943 3.80317 13.4323 2.65884 14.8297 2.66506L17.4874 2.67524C18.7129 2.6592 19.7041 3.66252 19.7201 4.88806L19.7205 5.18078C19.713 7.59366 21.4219 8.58033 23.5194 7.37412L23.773 7.22806C24.8425 6.62918 26.2069 6.98586 26.8058 8.05524L28.1434 10.3517C28.8475 11.5588 28.4255 13.1165 27.225 13.8091C25.1275 15.0153 25.1287 16.9867 27.2187 18.1933C28.4197 18.8867 28.8461 20.4415 28.1461 21.6539L26.9727 23.6861C26.2794 24.8871 24.7245 25.3135 23.5121 24.6135C21.4221 23.4068 19.7142 24.3915 19.7183 26.8109C19.7122 28.2084 18.5809 29.3412 17.1834 29.3351L14.5258 29.3248C13.3003 29.3409 12.3091 28.3376 12.2931 27.112L12.2927 26.8193C12.3002 24.4064 10.5912 23.4197 8.49376 24.626L8.24008 24.772C7.17071 25.3709 5.80624 25.0143 5.20736 23.9448L3.86973 21.6484C3.16561 20.4412 3.58767 18.8836 4.78811 18.1909C6.88555 16.9848 6.8844 15.0135 4.7944 13.8068C3.58196 13.1068 3.1738 11.5471 3.86713 10.3462Z"
        stroke="#1B1B1B"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.3334 16.0001C20.3334 13.6068 18.3933 11.6667 16.0001 11.6667C13.6068 11.6667 11.6667 13.6068 11.6667 16.0001C11.6667 18.3933 13.6068 20.3334 16.0001 20.3334C18.3933 20.3334 20.3334 18.3933 20.3334 16.0001Z"
        stroke="#1B1B1B"
        strokeWidth="2"
      />
    </svg>
  );
};
