import { gql } from '@apollo/client';

export const CREATE_TOKEN = gql`
  mutation createToken($token: CreateTokenInput!) {
    createToken(token: $token) {
      print
      deviceType
      id
      contract {
        evcoId
      }
    }
  }
`;

export const GET_TOKENS = gql`
  query getTokens($tokenFilter: TokenFilter) {
    tokens(tokenFilter: $tokenFilter) {
      id
      deviceType
      print
    }
  }
`;

export const UPDATE_TOKEN = gql`
  mutation updateToken($token: UpdateTokenInput!, $filter: TokenFilter!) {
    updateTokens(token: $token, filter: $filter)
  }
`;

export const DELETE_TOKEN = gql`
  mutation deleteToken($tokenId: String!) {
    deleteToken(tokenId: $tokenId)
  }
`;
