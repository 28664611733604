import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

import * as S from './styled';
import { BatteryAnimation, CopyIcon, ErrorIcon, PlayIcon, ReadyIcon, StopIcon } from '../../Icons';
import bg from '../../../assets/img/cardItemImg.png';
import station from '../../../assets/img/stationImg.png';
import { OfflineIcon } from '../../Icons/OfflineIcon';
import CommonModal from '../CommonModal';
import AvailableUsers from '../AvailableUsers';
import WarningModal from '../WarningModal';
import BatteryPreparingAnimated from '../../AnimatedIcons/battery-preparing-animated';
import ActionsWithUser from '../ActionsWithUser';
import ChartForCard from '../ChartForCard/ChartForCard';
import { CHARGING_STATUS_TYPES } from '../../../enums';
import { useUserInfo } from '../../../context';
import { CircularProgress } from '@mui/material';
import {roundTo1Decimal, roundTo2Decimal} from "../../../utils";
import {charge_amount_formating, cost_formating} from "../../../utils/custom_formating";
import {useQuery} from "@apollo/client";
import {GET_LATES_CHARGINSESSION} from "../../../apollo/chargingHistory";


const CardItem = ({
  value,
  startChargingProcess,
  stopChargingProcess,
  siteValue,
  loading,
  showErrorModal,
  handlerOpenEditModal
}) => {
  const { t } = useTranslation();
  const { isSiteAdmin, info } = useUserInfo();

  const [waiting, setWaiting] = useState(false);
  const [connectorValue, setConnectorValue] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenWarningModal, setIsOpenWarningModal] = useState(false);
  const [isOpenActionsModal, setIsOpenActionsModal] = useState(false);

  const { data: latest_chargingsession, loading: get_session_loading } = useQuery(GET_LATES_CHARGINSESSION, {
      variables: {
          filter: {
              evseId: value.evseId
          }
      }
  });

  const statusInfo = value?.connectorStates?.[0];
  const newestChargingSession = latest_chargingsession?.charging_latest_session_for_evse_id;
  const chargingInfo = statusInfo && JSON.parse(statusInfo?.message);



  const handlerChooseUser = async (user = null) => {
    await startChargingProcess(value, user);
    closeModal();
    setWaiting(true);
    setTimeout(() => setWaiting(false), 10000);
  };

  const handlerStartProcess = () => {
    if (isSiteAdmin) {
      setIsOpenModal(true);
    } else {
      handlerChooseUser();
    }
  };

  const handlerOpenActionsModal = () => {
    setIsOpenActionsModal(true);
    setConnectorValue(value);
  };

  const closeModal = () => setIsOpenModal(false);

  const closeWarningModal = () => setIsOpenWarningModal(false);

  const handlerStopProcess = async () => {
    await stopChargingProcess(value);
    closeWarningModal();
    setWaiting(true);
    setTimeout(() => setWaiting(false), 10000);
  };

  const handlerShowErrorModal = () => showErrorModal(value?.connectorStates?.[0]?.message);

  const setStatus = useMemo(() => {
    switch (statusInfo?.state) {
      case CHARGING_STATUS_TYPES.READY: {
        return (
          <>
            <S.StatusBlock>
              <ReadyIcon />
              <S.StatusText>{t('overviewWallboxes.status_ready')}</S.StatusText>
            </S.StatusBlock>

            <S.ActionBlock onClick={handlerStartProcess}>
              <PlayIcon />
            </S.ActionBlock>
          </>
        );
      }

      case CHARGING_STATUS_TYPES.SUSPENDED:
      case CHARGING_STATUS_TYPES.SUSPENDED_ENV: {
          if ( newestChargingSession && newestChargingSession?.user_id !== info.id && !isSiteAdmin)  {
              return (<>
                      <S.StatusBlockOccupied>
                          <BatteryAnimation />)
                          <S.StatusTextOccupied>{t('overviewWallboxes.occupied')}</S.StatusTextOccupied>
                      </S.StatusBlockOccupied>
                  </>
              );
          } else {
              return (
                  <>
                      <S.StatusBlock>
                          <BatteryPreparingAnimated />
                          <S.StatusText>{t('overviewWallboxes.suspended')}</S.StatusText>
                      </S.StatusBlock>

                      <S.ActionBlock onClick={handlerStartProcess}>
                          <PlayIcon />
                      </S.ActionBlock>
                  </>
              );
          }
      }

      case CHARGING_STATUS_TYPES.PREPARING: {
        return (
          <>
            <S.StatusBlock>
              <BatteryPreparingAnimated />
              <S.StatusText>{t('overviewWallboxes.preparing')}</S.StatusText>
            </S.StatusBlock>

            <S.ActionBlock onClick={handlerStartProcess}>
              <PlayIcon />
            </S.ActionBlock>
          </>
        );
      }

      case CHARGING_STATUS_TYPES.CHARGING : {
        if ( newestChargingSession && newestChargingSession?.user_id !== info.id && !isSiteAdmin)  {
            return (<>
                    <S.StatusBlockOccupied>
                        <BatteryAnimation />)
                        <S.StatusTextOccupied>{t('overviewWallboxes.occupied')}</S.StatusTextOccupied>
                    </S.StatusBlockOccupied>
                </>
            );
          } else {
            return (<>
                    <S.StatusBlock>
                        <BatteryAnimation />)
                        <S.StatusText>{t('overviewWallboxes.charging')}</S.StatusText>
                    </S.StatusBlock>
                    <S.ActionBlock charging={true} onClick={() => setIsOpenWarningModal(true)}>
                        <StopIcon />
                    </S.ActionBlock>
                </>
            );
        }

      }

      case CHARGING_STATUS_TYPES.ERROR: {
        return (
          <>
            <S.StatusBlock>
              <ErrorIcon />
              <S.StatusText>{t('overviewWallboxes.error')}</S.StatusText>
            </S.StatusBlock>

            <S.ActionBlock charging={true} onClick={handlerShowErrorModal}>
              <PriorityHighIcon style={{ fill: '#FB3E3F', width: '100%', height: '50px' }} />
            </S.ActionBlock>
          </>
        );
      }

      case CHARGING_STATUS_TYPES.OFFLINE: {
        return (
          <>
            <S.StatusBlock>
              <OfflineIcon />
              <S.StatusText>{t('overviewWallboxes.offline')}</S.StatusText>
              <S.OfflineLastDateText>{JSON.parse(statusInfo?.message)?.msg}</S.OfflineLastDateText>
            </S.StatusBlock>
          </>
        );
      }

      case CHARGING_STATUS_TYPES.NO_CONNECTION:
      case CHARGING_STATUS_TYPES.UNAVAILABLE: {
        return (
          <>
            <S.StatusBlock>
              <OfflineIcon />
              <S.StatusText>{t('overviewWallboxes.no_connection')}</S.StatusText>
              <S.OfflineLastDateText>{JSON.parse(statusInfo?.message)?.msg}</S.OfflineLastDateText>
            </S.StatusBlock>
          </>
        );
      }

      default:
        return (
          <>
            <S.StatusBlock>
              <ErrorIcon />
              <S.StatusText>{t('overviewWallboxes.error')}</S.StatusText>
            </S.StatusBlock>

            <S.ActionBlock charging={true} onClick={handlerShowErrorModal}>
              <PriorityHighIcon style={{ fill: '#FB3E3F', width: '100%', height: '50px' }} />
            </S.ActionBlock>
          </>
        );
    }
  }, [statusInfo, get_session_loading]);

  const setStatusWaiting = useMemo(() => {
    return (
      <>
        <S.StatusBlock>
          <CircularProgress />
          <S.StatusText>{t('overviewWallboxes.waiting')}</S.StatusText>
        </S.StatusBlock>
      </>
    );
  }, [waiting, get_session_loading]);

  const closeActionsModal = () => setIsOpenActionsModal(false);

  return (
    <S.CardWrapper>
      <CommonModal open={isOpenModal} onClose={closeModal}>
        <AvailableUsers
          onClose={closeModal}
          handlerChooseUser={handlerChooseUser}
          title={value?.name}
          users={value?.contracts}
        />
      </CommonModal>

      <CommonModal open={isOpenWarningModal} onClose={closeWarningModal}>
        <WarningModal
          onClose={closeWarningModal}
          handlerProcessedClick={handlerStopProcess}
          description={''}
        />
      </CommonModal>

      <CommonModal open={isOpenActionsModal} onClose={closeActionsModal}>
        <ActionsWithUser
          handlerCloseModal={closeActionsModal}
          connectorValue={connectorValue}
          activeUsers={value?.contracts}
          siteValue={siteValue}
        />
      </CommonModal>

      <S.ImageBlock
        image={bg}
        error={
          statusInfo?.state === CHARGING_STATUS_TYPES.ERROR ||
          statusInfo?.state === CHARGING_STATUS_TYPES.OFFLINE
        }>
        <S.StationImg src={station} alt="station" />
        {waiting || loading || get_session_loading ? setStatusWaiting : setStatus}
      </S.ImageBlock>

      <S.InfoBlock>
        <S.InfoHeader>
          <div>
            <S.InfoHeaderTitle>{value?.name}</S.InfoHeaderTitle>
            <S.InfoHeaderSubtitle>{value?.evseId}</S.InfoHeaderSubtitle>
          </div>

          {isSiteAdmin && (
            <CopyIcon width={'13px'} height={'13px'} onClick={() => handlerOpenEditModal(value)} />
          )}
        </S.InfoHeader>

        { statusInfo?.state === CHARGING_STATUS_TYPES.PREPARING  ||
            ((statusInfo?.state === CHARGING_STATUS_TYPES.CHARGING ) && (isSiteAdmin || newestChargingSession.user_id === info.id)) ? (
          <>
            <S.Content>
              <S.ContentItem>
                <S.ContentItemTitle>{t('overviewWallboxes.start')}</S.ContentItemTitle>
                <S.ContentItemData>
                  {chargingInfo?.starttime
                    ? moment(chargingInfo?.starttime).format('YYYY-MM-DD HH:mm')
                    : '-'}
                </S.ContentItemData>
              </S.ContentItem>

              <S.ContentBorder />

              <S.ContentItem>
                <S.ContentItemTitle>{t('overviewWallboxes.duration')}</S.ContentItemTitle>
                <S.ContentItemData>
                  {chargingInfo?.duration
                    ? moment.utc(chargingInfo?.duration * 1000).format('HH:mm:ss')
                    : '00:00:00'}
                </S.ContentItemData>
              </S.ContentItem>
            </S.Content>

            <S.Content>
              <S.ContentItem>
                <S.ContentItemTitle>{t('overviewWallboxes.power_consumption')}</S.ContentItemTitle>
                <S.ContentItemData>
                  {chargingInfo?.amount ? charge_amount_formating(chargingInfo?.amount) : 0} kWh
                </S.ContentItemData>
              </S.ContentItem>

              <S.ContentBorder />

              <S.ContentItem>
                <S.ContentItemTitle>{t('overviewWallboxes.costs')}</S.ContentItemTitle>
                <S.ContentItemData>€{ chargingInfo?.cost ? cost_formating(chargingInfo?.cost) : 0}</S.ContentItemData>
              </S.ContentItem>
            </S.Content>
          </>
        ) : (statusInfo?.state === CHARGING_STATUS_TYPES.SUSPENDED ||
            statusInfo?.state === CHARGING_STATUS_TYPES.SUSPENDED_ENV) && (isSiteAdmin || newestChargingSession.user_id === info.id) ?
            (          <>
                <S.Content>
                    <S.ContentItem>
                        <S.ContentItemTitle>{t('overviewWallboxes.start')}</S.ContentItemTitle>
                        <S.ContentItemData>
                            {chargingInfo?.last_charging_details?.starttime
                                ? moment(chargingInfo?.last_charging_details?.starttime).format('YYYY-MM-DD HH:mm')
                                : '-'}
                        </S.ContentItemData>
                    </S.ContentItem>

                    <S.ContentBorder />

                    <S.ContentItem>
                        <S.ContentItemTitle>{t('overviewWallboxes.duration')}</S.ContentItemTitle>
                        <S.ContentItemData>
                            {chargingInfo?.last_charging_details?.duration
                                ? moment.utc(chargingInfo?.last_charging_details?.duration * 1000).format('HH:mm:ss')
                                : '00:00:00'}
                        </S.ContentItemData>
                    </S.ContentItem>
                </S.Content>

                <S.Content>
                    <S.ContentItem>
                        <S.ContentItemTitle>{t('overviewWallboxes.power_consumption')}</S.ContentItemTitle>
                        <S.ContentItemData>
                            {chargingInfo?.last_charging_details?.amount ? charge_amount_formating(chargingInfo?.last_charging_details?.amount) : 0} kWh
                        </S.ContentItemData>
                    </S.ContentItem>

                    <S.ContentBorder />

                    <S.ContentItem>
                        <S.ContentItemTitle>{t('overviewWallboxes.costs')}</S.ContentItemTitle>
                        <S.ContentItemData>€{ chargingInfo?.last_charging_details?.cost ? cost_formating(chargingInfo?.last_charging_details?.cost) : 0}</S.ContentItemData>
                    </S.ContentItem>
                </S.Content>
            </>)
            : (
                <ChartForCard evseId={value?.evseId} />
        )}

        {isSiteAdmin && (
          <>
            <S.UserBlockTitle>{t('overviewWallboxes.manage_users')}</S.UserBlockTitle>
            <S.UserBlock>
              {value?.contracts?.length
                ? value?.contracts?.map((item) => (
                    <S.UserItem key={item?.evcoId}>{item?.name}</S.UserItem>
                  ))
                : null}
              <S.UserCopyIcon>
                <CopyIcon width={14} height={14} onClick={handlerOpenActionsModal} />
              </S.UserCopyIcon>
            </S.UserBlock>
          </>
        )}
      </S.InfoBlock>
    </S.CardWrapper>
  );
};

export default CardItem;
