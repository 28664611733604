import styled from 'styled-components';

const Content = styled.div`
  position: relative;
  width: 100%;
  padding: 32px 32px 32px 32px;
  display: flex;
  max-height: 100vh;
  overflow-y: scroll;
  justify-content: center;
  @media (${({ theme }) => theme.base.breakpoint.XL}) {
    padding: 0;
    overflow-y: inherit;
    max-height: 100%;
    min-height: 100vh;
  }
`;

const Layout = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  position: relative;
  background: ${({ theme }) => theme.color.background.primary};
  @media (${({ theme }) => theme.base.breakpoint.XL}) {
    padding-left: 0;
    height: 100%;
  }
`;

export { Content, Layout };
