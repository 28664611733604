import React, { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import * as S from './styled';
import ContentHeader from '../../components/UI/ContentHeader';
import { useTranslation } from 'react-i18next';
import { GeneralSettings, UserManagement } from './Tabs';

const SettingsPage = () => {
  const { t } = useTranslation();

  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (e, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  return (
    <S.Wrapper
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 50 }}
      transition={{ duration: 0.3 }}>
      <ContentHeader showTab={false} />

      <S.Content>
        <Tabs value={tabIndex} onChange={handleTabChange}>
          <Tab label={t('settings.tariff_settings')} />
          <Tab label={t('settings.user_management')} />
        </Tabs>

        <S.ContentBlock>
          {tabIndex === 0 && <GeneralSettings />}
          {tabIndex === 1 && <UserManagement />}
        </S.ContentBlock>
      </S.Content>
    </S.Wrapper>
  );
};

export default SettingsPage;
