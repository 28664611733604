import React from 'react';
import styled from 'styled-components';
import { Button } from '@mui/material';
import { textMedium } from '../../../styles/texts';

const CommonButton = ({ onClick, text, ...rest }) => {
  return (
    <StyledButton {...rest} onClick={onClick} variant={'contained'}>
      {text}
    </StyledButton>
  );
};

const StyledButton = styled(Button)`
  ${textMedium};
  border-radius: 8px;
  text-transform: capitalize;
  padding: 10px 18px;
  min-width: 176px;
  color: ${({ theme }) => theme.color.background.secondary};
`;

export default CommonButton;
