import React from 'react';

export const BatteryAnimation = (props) => (
  <svg
    id="eQbUFCctiRS1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 84 46"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    {...props}
  >
    <style>
      {
        "@keyframes eQbUFCctiRS3_f_p{0%,to{fill:#fff}75.471698%{fill:#fff;animation-timing-function:cubic-bezier(.42,0,.8,1)}92.45283%{fill:#4b93ff;animation-timing-function:cubic-bezier(.335856,0,.669386,.350311)}96.226415%{fill:#4e95ff;animation-timing-function:cubic-bezier(.421728,.12694,.795993,1)}}@keyframes eQbUFCctiRS4_f_p{0%,to{fill:#fff}56.603774%{fill:#fff;animation-timing-function:cubic-bezier(.42,0,.8,1)}75.471698%{fill:#4b93ff;animation-timing-function:cubic-bezier(.334662,0,.668055,.342429)}92.45283%{fill:#4c94ff;animation-timing-function:cubic-bezier(.421046,.073806,.797861,1)}}@keyframes eQbUFCctiRS5_f_p{0%,to{fill:#fff}37.735849%{fill:#fff;animation-timing-function:cubic-bezier(.42,0,.8,1)}56.603774%{fill:#4b93ff;animation-timing-function:cubic-bezier(.334235,0,.667596,.339546)}88.679245%{fill:#4b93ff;animation-timing-function:cubic-bezier(.420746,.052032,.798541,1)}}@keyframes eQbUFCctiRS6_f_p{0%,to{fill:#fff}18.867925%{fill:#fff;animation-timing-function:cubic-bezier(.42,0,.8,1.005)}37.735849%{fill:#4b93ff;animation-timing-function:cubic-bezier(.33398,0,.667328,.337822)}84.90566%{fill:#4b93ff;animation-timing-function:cubic-bezier(.420551,.038434,.79895,1.004906)}}@keyframes eQbUFCctiRS7_f_p{0%{fill:#fff;animation-timing-function:cubic-bezier(.42,0,.8,1)}18.867925%{fill:#4b93ff;animation-timing-function:cubic-bezier(.333848,0,.667191,.336904)}79.245283%{fill:#4b93ff;animation-timing-function:cubic-bezier(.420446,.030817,.799163,1)}to{fill:#fff}}"
      }
    </style>
    <path
      d="M70.993 3.45c1.587-.028 3.12.576 4.264 1.678s1.804 2.613 1.838 4.201v27.269a6.003 6.003 0 0 1-6.103 5.88h-6.845V3.45h6.846Z"
      transform="translate(-.095)"
      fill="#fff"
      style={{
        animation: "eQbUFCctiRS3_f_p 5300ms linear infinite normal forwards",
      }}
    />
    <path
      d="M61.987 42.5V3.46H49.04V42.5h12.948Z"
      transform="translate(.013)"
      fill="#fff"
      style={{
        animation: "eQbUFCctiRS4_f_p 5300ms linear infinite normal forwards",
      }}
    />
    <path
      d="M46.878 42.52V3.481H33.93v39.04h12.948Z"
      transform="translate(.013)"
      fill="#fff"
      style={{
        animation: "eQbUFCctiRS5_f_p 5300ms linear infinite normal forwards",
      }}
    />
    <path
      d="M31.781 42.541V3.502H18.833v39.039h12.948Z"
      transform="translate(.013)"
      fill="#fff"
      style={{
        animation: "eQbUFCctiRS6_f_p 5300ms linear infinite normal forwards",
      }}
    />
    <path
      d="M9.926 42.56c-1.586.027-3.118-.577-4.26-1.68s-1.801-2.612-1.833-4.2V9.411a5.992 5.992 0 0 1 6.093-5.88h6.737v39.03H9.926Z"
      transform="translate(.18)"
      fill="#fff"
      style={{
        animation: "eQbUFCctiRS7_f_p 5300ms linear infinite normal forwards",
      }}
    />
    <path
      d="M81.572 38.236V7.774A7.768 7.768 0 0 0 73.805 0H7.767C5.707 0 3.73.819 2.275 2.277S0 5.712 0 7.774v30.462a7.77 7.77 0 0 0 4.795 7.176c.942.39 1.952.59 2.972.588h66.038a7.751 7.751 0 0 0 7.176-4.79 7.77 7.77 0 0 0 .591-2.974Zm-72.151 5.95a7.236 7.236 0 0 1-6.74-4.402 7.254 7.254 0 0 1-.581-2.768V8.984c.023-1.92.808-3.754 2.18-5.096s3.222-2.085 5.141-2.063h62.244c1.92-.022 3.768.72 5.141 2.063s2.157 3.175 2.18 5.096v28.032a7.262 7.262 0 0 1-2.18 5.1 7.248 7.248 0 0 1-5.14 2.07H9.42Z"
      fill="#4b93ff"
    />
    <path
      d="M80.206 32.915h1.525c1.249 0 2.269-1.567 2.269-3.51V15.63c0-1.983-.99-3.52-2.269-3.52h-1.525v20.804Z"
      fill="#4b93ff"
    />
  </svg>
);
