import styled from 'styled-components';
import { subTitleText } from '../../styles/texts';
import { motion } from 'framer-motion';

const Wrapper = styled(motion.div)`
  width: 100%;
  min-height: 65vh;

  @media (${({ theme }) => theme.base.breakpoint.XL}) {
    padding: 0 16px;
  }
`;

const Title = styled.p`
  margin-top: 24px;
  margin-bottom: 10px;
  ${subTitleText};
  font-weight: bold;
`;

const Content = styled.div`
  min-height: 70vh;
  margin-top: 20px;
  padding: 20px;
  background: ${({ theme }) => theme.color.background.secondary};
  box-shadow: 0 2px 10px rgba(51, 51, 51, 0.1);
  border-radius: 15px;
  overflow-y: scroll;
`;

const FormBlock = styled.form``;

export { Wrapper, FormBlock, Title, Content };
