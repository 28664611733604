import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './styled';
import ModalInviteUser from './ModalInviteUser';
import CommonModal from '../../../../components/UI/CommonModal';
import CommonButton from '../../../../components/UI/button';
import AlertMessage from '../../../../components/UI/AlertMessage';
import TableUserManagement from '../../../../components/UI/TableUserManagement';
import { ALERT_TYPES } from '../../../../enums';

const UserManagement = () => {
  const { t } = useTranslation();

  const [isOpenActionModal, setIsOpenActionModal] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertText, setAlertText] = useState('');
  const [alertStatus, setAlertStatus] = useState(ALERT_TYPES.SUCCESS);

  const handlerCloseActionModal = () => {
    setIsOpenActionModal(false);
  };

  const handlerAction = (text, status = ALERT_TYPES.SUCCESS) => {
    setAlertText(text);
    setAlertStatus(status);
    setOpenAlert(true);
  };

  const closeAlert = () => {
    setAlertText('');
    setOpenAlert(false);
  };

  const handlerOpenActionModal = () => setIsOpenActionModal(true);

  return (
    <S.Wrapper>
      <AlertMessage
        open={openAlert}
        onClose={closeAlert}
        errorText={alertText}
        severity={alertStatus}
      />
      <CommonModal open={isOpenActionModal} onClose={handlerCloseActionModal}>
        <ModalInviteUser onClose={handlerCloseActionModal} handlerAction={handlerAction} />
      </CommonModal>
      <TableUserManagement />

        <S.InviteButtonBlock>
            <CommonButton text={t('modal.invite_user')} onClick={handlerOpenActionModal} />
        </S.InviteButtonBlock>

    </S.Wrapper>
  );
};

export default UserManagement;
