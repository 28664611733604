import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useMutation } from '@apollo/client';

import * as S from './styled';
import CommonModalWithHeader from '../../../CommonModalWithHeader';
import Input from '../../../Input';
import { CREATE_TOKEN, GET_TOKENS, UPDATE_TOKEN } from '../../../../../apollo/token';
import { validateVerificationID } from '../../../../../utils';
import { USER_ACTION_TYPES } from '../../../../../enums';

const UserToken = ({ edit = false, handlerChangeModal, userData, editData, handlerActions }) => {
  const { t } = useTranslation();

  const [addToken, { error: addTokenError }] = useMutation(CREATE_TOKEN, {
    refetchQueries: [
      {
        query: GET_TOKENS,
        variables: {
          tokenFilter: {
            id: null,
            evcoId: userData?.evcoId
          }
        }
      }
    ]
  });
  const [updateToken, { error: updateTokenError }] = useMutation(UPDATE_TOKEN, {
    refetchQueries: [
      {
        query: GET_TOKENS,
        variables: {
          tokenFilter: {
            id: null,
            evcoId: userData?.evcoId
          }
        }
      }
    ]
  });

  const validationSchema = Yup.object({
    id: Yup.string()
      .test(function (value) {
        if (!validateVerificationID(value)) {
          return this.createError({ message: t('auth.id_invalid'), path: 'id' });
        }

        return true;
      })
      .required(t('auth.id_required'))
  });

  const submitForm = async (value) => {
    if (!edit) {
      const variables = {
        token: {
          deviceType: value?.deviceType,
          id: value?.id,
          print: value?.name,
          evcoId: userData?.evcoId
        }
      };

      await addToken({ variables });

      handlerActions(t('modal.token_create_success'));
      handlerChangeModal(USER_ACTION_TYPES.EDIT);
    } else {
      const variables = {
        token: {
          deviceType: value?.deviceType,
          print: value?.name
        },
        filter: {
          id: editData?.id,
          evcoId: userData?.evcoId
        }
      };

      await updateToken({ variables });

      handlerActions(t('modal.token_edit_success'));
      handlerChangeModal(USER_ACTION_TYPES.EDIT);
    }
  };

  const formik = useFormik({
    initialValues: edit
      ? { name: editData?.print, id: editData?.id, deviceType: editData?.deviceType }
      : validationSchema.cast(),
    validationSchema: validationSchema,
    validateOnChange: false,
    validateOnBlur: true,
    validateOnSubmit: true,
    onSubmit: submitForm
  });

  if (addTokenError) {
    return <p>Error: {addTokenError?.message}...</p>;
  } else if (updateTokenError) {
    return <p>Error: {updateTokenError?.message}...</p>;
  }

  return (
    <CommonModalWithHeader
      title={edit ? t('modal.edit_user_token') : t('modal.add_user_token')}
      buttonText={t('modal.submit')}
      onClose={() => handlerChangeModal(USER_ACTION_TYPES.EDIT)}
      buttonFunc={formik.handleSubmit}>
      <S.Form>
        <Input
          label={t('modal.name')}
          id="name"
          name="name"
          type="text"
          onChange={formik.handleChange}
          value={formik.values.name || ''}
          onBlur={formik.handleBlur}
        />
        <Input
          label={t('modal.id')}
          id="id"
          name="id"
          type="text"
          onChange={formik.handleChange}
          disabled={edit}
          value={formik.values.id || editData?.id || ''}
          onBlur={formik.handleBlur}
          error={formik.touched.id && Boolean(formik.errors.id)}
          helperText={formik.touched.id && formik.errors.id}
        />
        <Input
          label={t('modal.type')}
          id="deviceType"
          name="deviceType"
          type="text"
          onChange={formik.handleChange}
          value={formik.values.deviceType || ''}
          onBlur={formik.handleBlur}
        />
      </S.Form>
    </CommonModalWithHeader>
  );
};

export default UserToken;
