import styled from 'styled-components';
import { textSmall } from '../../styles/texts';
import CommonButton from '../../components/UI/button';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Title = styled.h1`
  margin-bottom: 40px;
  ${textSmall};
  font-size: 40px;
  line-height: 44px;
  font-weight: 700;
`;

const Button = styled(CommonButton)`
  width: 60%;
`;

export { Wrapper, Title, Button };
