import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import * as S from './styled';
import Input from '../Input';
import { setRefreshToken, setToken } from '../../../utils/localStorage';
import { FacebookIcon, GoogleIcon, TwitterIcon } from '../../Icons';
import Checkbox from '../Checkbox';
import { api } from '../../../services';
import AlertMessage from '../AlertMessage';
import { ALERT_TYPES } from '../../../enums';

const AuthFormBlock = ({ isLogin = true, isActivate = false, activateData = null }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [alertSeverity, setAlertSeverity] = useState(ALERT_TYPES.ERROR);

  const [showPassword, setShowPassword] = useState(false);
  const [checkboxData, setCheckboxData] = useState({
    name: 'Remember me',
    value: 'remember',
    checked: true
  });

  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email').required(t('auth.email_required')),
    fullName: Yup.string().required(t('auth.name_required')).default(''),
    password: Yup.string()
      .min(8, t('password_minimum_character'))
      .required(t('auth.password_required'))
      .default(''),
    password_repeat: Yup.string().when('password', {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], 'Both password need to be the same')
    })
  });

  const validationSchemaLogin = Yup.object({
    email: Yup.string().email('Invalid email').required(t('auth.email_required')),
    password: Yup.string()
      .min(8, t('password_minimum_character'))
      .required(t('auth.password_required'))
      .default('')
  });

  const submitForm = async (values) => {
    if (isActivate) {
      try {
        const data = {
          otp: id,
          fullName: values?.fullName,
          password: values?.password
        };
        const response = await api.invitation.confirmInvitation(data);

        setOpenSnackbar(true);
        setAlertSeverity(ALERT_TYPES.SUCCESS);
        setErrorText(response?.data?.message);

        navigate('/login');
      } catch (e) {
        setOpenSnackbar(true);
        setAlertSeverity(ALERT_TYPES.ERROR);
        setErrorText(e.response.data.message);
      }
    } else if (!isLogin) {
      const { password_repeat, ...data } = values;
      try {
        const response = await api.authenticate.registration(data);

        setOpenSnackbar(true);
        setAlertSeverity(ALERT_TYPES.SUCCESS);
        setErrorText(response?.data?.message);

        navigate('/verify-page');
      } catch (e) {
        setOpenSnackbar(true);
        setAlertSeverity(ALERT_TYPES.ERROR);
        setErrorText(e.response.data.message);
      }
    } else {
      const data = {
        username: values.email,
        password: values.password,
        grant_type: 'password'
      };

      try {
        const response = await api.authenticate.login(data);
        setToken(response?.data?.access_token);
        setRefreshToken(response?.data?.refresh_token);
        if (response?.data?.expires_in < 0) {
          await api.authenticate.refreshToken();
        }
        navigate('/');
      } catch (e) {
        setOpenSnackbar(true);
        setAlertSeverity(ALERT_TYPES.ERROR);
        setErrorText(e.response.data.error_description);
      }
    }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const formikDefault = useFormik({
    initialValues: isLogin ? validationSchemaLogin.cast() : validationSchema.cast(),
    validationSchema: isLogin ? validationSchemaLogin : validationSchema,
    validateOnChange: false,
    validateOnBlur: true,
    validateOnSubmit: true,
    onSubmit: submitForm
  });

  const formikActivate = useFormik({
    initialValues: { fullName: activateData?.fullName, email: activateData?.email },
    validationSchema: isLogin ? validationSchemaLogin : validationSchema,
    validateOnChange: false,
    validateOnBlur: true,
    validateOnSubmit: true,
    onSubmit: submitForm
  });

  const formik = isActivate ? formikActivate : formikDefault;

  const handleChecked = () => {
    const prevState = { ...checkboxData };
    const newState = { ...prevState };
    newState.checked = !prevState.checked;
    setCheckboxData(newState);
  };

  const closeSnackbar = () => setOpenSnackbar(false);

  return (
    <S.Wrapper>
      <AlertMessage
        open={openSnackbar}
        onClose={closeSnackbar}
        errorText={errorText}
        severity={alertSeverity}
      />
      <S.HeaderBlock>
        <S.HeaderText>
          {isLogin ? t('auth.dont_have_account') : t('auth.do_have_account')}
        </S.HeaderText>

        <S.HeaderTextBold to={isLogin ? '/sign-in' : '/login'}>
          &nbsp;{t('auth.get_started')}
        </S.HeaderTextBold>
      </S.HeaderBlock>

      <S.FormBlock>
        <S.FormTitle>{isLogin ? t('auth.login') : t('auth.sign_in')}</S.FormTitle>
        <S.FormDescription>{t('auth.description')}</S.FormDescription>
        {!isLogin && (
          <Input
            label={t('auth.name')}
            id="fullName"
            name="fullName"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.fullName || ''}
            onBlur={formik.handleBlur}
            error={formik.touched.fullName && Boolean(formik.errors.fullName)}
            helperText={formik.touched.fullName && formik.errors.fullName}
          />
        )}

        <Input
          label={t('auth.email_address')}
          id="email"
          name="email"
          type="email"
          disabled={isActivate}
          onChange={formik.handleChange}
          value={formik.values.email || ''}
          onBlur={formik.handleBlur}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />

        <Input
          label={t('auth.password')}
          id="password"
          name="password"
          type={showPassword ? 'text' : 'password'}
          onChange={formik.handleChange}
          value={formik.values.password || ''}
          onBlur={formik.handleBlur}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />

        {!isLogin && (
          <Input
            label={t('auth.password_repeat')}
            id="password_repeat"
            name="password_repeat"
            type={showPassword ? 'type' : 'password'}
            onChange={formik.handleChange}
            value={formik.values.password_repeat || ''}
            onBlur={formik.handleBlur}
            error={formik.touched.password_repeat && Boolean(formik.errors.password_repeat)}
            helperText={formik.touched.password && formik.errors.password_repeat}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        )}

        {false && (
          <>
            <S.DivideBlock>OR</S.DivideBlock>

            <S.SocialBlock>
              <S.SocialBlockItem>
                <GoogleIcon />
              </S.SocialBlockItem>

              <S.SocialBlockItem>
                <FacebookIcon />
              </S.SocialBlockItem>

              <S.SocialBlockItem>
                <TwitterIcon />
              </S.SocialBlockItem>
            </S.SocialBlock>
          </>
        )}

        {isLogin && (
          <S.CheckboxBlock>
            <Checkbox
              value={checkboxData?.value}
              label={checkboxData?.name}
              checked={checkboxData?.checked}
              onChecked={handleChecked}
            />
            <S.ForgotPassword onClick={() => navigate('/reset-password')}>
              {t('auth.forgot_password')}
            </S.ForgotPassword>
          </S.CheckboxBlock>
        )}

        <S.ActionButton
          text={isLogin ? t('auth.login') : t('auth.sign_in')}
          onClick={formik.handleSubmit}
        />

        <S.NotAccountBlock>
          <S.NotAccountTitle>
            {isLogin ? t('auth.dont_have_account') : t('auth.do_have_account')}
          </S.NotAccountTitle>
          <S.LinkForPage to={isLogin ? '/sign-in' : '/login'}>
            {isLogin ? t('auth.sign_up') : t('auth.login')}
          </S.LinkForPage>
        </S.NotAccountBlock>

        <S.NotAccountBlock>
          <S.NotAccountTitle>{t('auth.terms_text')}</S.NotAccountTitle>
          <S.Links><a href="https://lade-plus.de/downloads/AGB%20Betriebsf%C3%BChrung%20und%20Abrechnung%20Ladeinfrastruktur%20Endkunden%202023-08-01.pdf">{t('auth.terms_service')}</a></S.Links>
          <S.NotAccountTitle>And</S.NotAccountTitle>
          <S.Links><a href="https://lade-plus.de/datenschutzerklarung/">{t('auth.privacy_policy')}</a></S.Links>
        </S.NotAccountBlock>
      </S.FormBlock>
    </S.Wrapper>
  );
};

export default AuthFormBlock;
