import {
    Column, ColumnChooser, ColumnFixing,
    DataGrid,
    Editing, FilterRow,
    HeaderFilter,
    Item,
    Pager,
    Paging, Scrolling, SearchPanel,
    Sorting,
    Toolbar
} from "devextreme-react/data-grid";
import {clientForUserTable} from "../../../apollo/client";
import {CREATE_TOKEN, DELETE_TOKEN, GET_TOKENS, UPDATE_TOKEN} from "../../../apollo/token";
import CustomStore from "devextreme/data/custom_store";
import {Button} from "devextreme-react/button";
import React, {useRef, useState} from "react";


const Token = (props) => {

    const dataGrid = useRef(null);

    const datastore = new CustomStore({
        key: 'id',
        load: () => {
            return getData();
        },
        remove: (key) => deleteToken(key),
        update: (key, values) => updateToken(key, values),
        insert: (values) => createToken(values)
    });

    const [store, setStore] = useState(datastore);

    function reloadDataGrid() {
        dataGrid.current.instance.refresh()
        /*const newCustomStore = new CustomStore({
            key: 'id',
            load: () => {
                return getData();
            },
            remove: (key) => deleteToken(key),
            update: (key, values) => updateToken(key, values),
            insert: (values) => createToken(values)
        });
        setStore(newCustomStore)*/
    }
    function deleteToken(id){
        console.log("deleteToken: "+id)
        return clientForUserTable.mutate({
            mutation: DELETE_TOKEN,
            variables: {
                tokenId: id
            }
        }
        ).then((res) =>{
                reloadDataGrid();
                return res
            }
        )
    }

    function updateToken( id, value ){
        console.log("update Token")
        console.log(value)
        return clientForUserTable.mutate({
            mutation: UPDATE_TOKEN,
            variables: {
                token: {
                    deviceType: value?.deviceType,
                    print: value?.print
                },
                filter: {
                    id: id,
                }
            }
        }).then( (res) => {
            reloadDataGrid();
            return res;
        } )


    }

    function createToken( value ){
        return clientForUserTable.mutate({
            mutation: CREATE_TOKEN,
            variables: {
                token: {
                    deviceType: value?.deviceType,
                    id: value?.id,
                    print: value?.print,
                    evcoId: props.data.data.evcoId
                }
            }
        }).then((res) =>{
                reloadDataGrid();
                return res
            }
        )
    }

    function getData() {
        return clientForUserTable.query({
            query: GET_TOKENS,
            variables: {
                tokenFilter: {
                    evcoId: props.data.data.evcoId
                }
            }
        }).then(
            (res) => {
                let result = res.data.tokens;
                console.log(result)
                return result;
            }
        )
    }

    const cellPrepared = (e) => {
        const editColumns = ["deviceType","id","print"]
        console.log(e.rowType)
        if ( (e.rowType === "data" || e.rowType === "header" ) && e.column.name) {
            console.log(e.rowType)
            if( !editColumns.includes(e.column.name) && (e.column.name !== "") && (e.column.name !== null) && (e.column.name !== "buttons")){
                //e.cellElement.style.cssText = "background-color:rgba(144,238,144, 0.1);";
                //e.cellElement.style.cssText = "background-color:rgba(255,0,255, 0.05);";
                e.cellElement.style.cssText = "background-color:rgba(211, 211, 211, 0.25);";
                //e.cellElement.style.cssText = "background-color:rgba(211, 211, 211, 0.2);";
                //e.cellElement.style.cssText = "color: black;";
            }
        }
    }

    return (
        <DataGrid
            id="token-grid"
            ref={dataGrid}
            showBorders={false}
            showColumnLines={false}
            showRowLines={false}
            dataSource={store}
            repaintChangesOnly={true}
            columnAutoWidth={true}
            allowColumnResizing={true}
            columnResizingMode={"widget"}
            onCellPrepared={cellPrepared}
        >
            <Toolbar>
                <Item location="before">
                    <h3 className="SubTitle">Tokens</h3>
                </Item>
                <Item name="addRowButton" location="after">
                </Item>
                <Item name="saveButton" location="after">
                </Item>
                <Item name="revertButton" location="after">
                </Item>
            </Toolbar>
            <Paging defaultPageSize={"all"} />
            <Editing
                useIcons={true}
                refreshMode={'reshape'}
                mode="batch"
                allowAdding={true}
                allowDeleting={true}
                allowUpdating={true}
            />
            <Sorting mode="multiple" />
            <Scrolling
                rowRenderingMode='standart'
            />
            <ColumnFixing enabled={true} />
            {/* or "dragAndDrop" */}

            <Column dataField="id" caption="id" dataType="string">
            </Column>

            <Column dataField="deviceType" caption="deviceType" dataType="string">
            </Column>

            <Column dataField="print" caption="print" dataType="string">
            </Column>

        </DataGrid>
    )
}

export default Token