import React from 'react';
import Box from '@mui/material/Box';

import * as S from './styled';

const CommonModal = ({ open, onClose, children, ...props }) => {
  return (
    <S.ModalWrapper open={open} onClose={onClose} {...props}>
      <Box>{children}</Box>
    </S.ModalWrapper>
  );
};

export default CommonModal;
