import styled from 'styled-components';
import { textSmall, titleText } from '../../../styles/texts';
import CommonButton from '../button';

const WarningWrapper = styled.div`
  width: 364px;
  background: ${({ theme }) => theme.color.background.secondary};
  border: 1px solid ${({ theme }) => theme.color.background.outline};
  border-radius: 8px;

  @media (${({ theme }) => theme.base.breakpoint.XL}) {
    width: 344px;
  }
`;

const Header = styled.div`
  padding: 18px 18px 0;
  display: flex;
  align-items: center;
  justify-content: end;

  div {
    cursor: pointer;
  }
`;

const Content = styled.div`
  margin-top: 42px;
  padding: 0 27px 70px;
`;

const ContentTitle = styled.p`
  text-align: center;
  ${titleText};
  line-height: 29px;
  color: ${({ theme }) => theme.color.text.primary.dark};
`;

const ContentDescription = styled.p`
  margin: 10px 0 24px;
  text-align: center;
  ${textSmall};
  font-weight: 500;
  color: ${({ theme }) => theme.color.text.primary.description};
`;

const ContentActionsBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Button = styled(CommonButton)`
  max-width: 140px;
  min-width: 140px;
  width: 100%;
  padding: 10px 38px;
  background: ${({ theme, cancel }) =>
    cancel ? theme.color.background.secondary : theme.color.main.primary};
  ${({ theme, cancel }) =>
    cancel &&
    `
    box-shadow: 0px 2px 10px rgba(51, 51, 51, 0.15);
    color: ${theme.color.text.primary.dark};
  `};

  &:hover {
    ${({ theme, cancel }) =>
      cancel &&
      `
      background: ${theme.color.text.primary.lightDark};
      color: ${theme.color.background.secondary};
    `}
  }
`;

export {
  WarningWrapper,
  Header,
  Content,
  ContentTitle,
  ContentDescription,
  ContentActionsBlock,
  Button
};
